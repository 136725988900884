import React, { useCallback } from "react";
import PropTypes from "prop-types";
import { charaNames, categoryNames, costumeNames } from "../translatedNames";
import { LazyLoadImage } from "react-lazy-load-image-component";
import _ from "lodash";
import { Lightbox } from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
import "yet-another-react-lightbox/plugins/counter.css";
import Download from "yet-another-react-lightbox/plugins/download";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import Counter from "yet-another-react-lightbox/plugins/counter";
// import { Button } from "bootstrap";

function Gallery(props) {
  const { imageData, setResultNum } = props;
  const [shuffle, setShuffle] = React.useState(false);
  // 洗牌資料
  const shuffledData = React.useMemo(() => {
    const copyImageData = [...imageData];
    for (let i = copyImageData.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [copyImageData[i], copyImageData[j]] = [copyImageData[j], copyImageData[i]];
    }
    return copyImageData;
  }, [imageData]);

  // 定义默认的 filter，即展示所有图片
  const [filter, setFilter] = React.useState({ name: "", chara: "", category: "" });

  // 定义 chara 跟 cate 的选项列表
  const charaOptions = Array.from(new Set(imageData.map((image) => image.chara)));
  const cateOptions = Array.from(new Set(imageData.map((image) => image.category)));
  const costumeOptions = Array.from(new Set(imageData.map((image) => image.name)));

  // 根据 filter 筛选图片数据
  const filterData = (imageData) => {
    const { name, chara, category } = filter;

    return imageData.filter((image) => {
      return (
        (name === "" || image.name.startsWith(name.toLowerCase())) &&
        (chara === "" || image.chara === chara) &&
        (category === "" || image.category.startsWith(category.toLowerCase()))
      );
    });
  };

  // 取得筛选后的洗牌数据
  const filteredShuffledData = filterData(shuffle? shuffledData : imageData);

  const [open, setOpen] = React.useState(false);

  // 點擊圖片時打開原始圖片
  const [lightboxIndex, setLightboxIndex] = React.useState(0);
  const openImage = (image, index) => {
    // window.open(image.image, "_blank", "noreferrer noopener");
    setOpen(true);
    setLightboxIndex(index);
  };

  // 搜尋欄防抖動
  
  const debounceSearch = useCallback(
    _.debounce((event) => {
      const inputName = event.target.value;
      if (inputName === "") {
        setFilter({ ...filter, name: "" });
        return;
      }
      // 查找對應的英文名稱
      const translatedName = Object.keys(costumeNames).find(key => costumeNames[key].includes(inputName));
      // console.log(translatedName + ", " + inputName + ": " + translatedName || inputName);
      setFilter({ ...filter, name: translatedName || inputName });
    }, 500),
    []
  );

  return (
    <div>
      <Lightbox
          open={open}
          styles={{ container: { backgroundColor: "rgba(0, 0, 0, .8)" } }}
          close={() => setOpen(false)}
          index={lightboxIndex}
          controller={{closeOnBackdropClick: true}}
          // carousel={{padding: "16px", spacing: "30%"}}
          slides={filteredShuffledData.map((image) => ({...image, src: `${image.image}`}))}
          plugins={[Download, Zoom, Counter]}
          zoom={{scrollToZoom: true}}
      />
      <div className="filter">
        <label>
          <button
            onClick={() => setShuffle(!shuffle)}
          >
            隨機排序{shuffle?"ON":"OFF"}
          </button>
        </label>
        <label>
          時裝名稱:
          <input
            type="text"
            list="costumeNames"
            //value={filter.name}
            // onChange={(e) => {
            //   const inputName = e.target.value;
            //    // 查找對應的英文名稱
            //   const translatedName = Object.keys(costumeNames).find(key => costumeNames[key].includes(inputName || " "));
            //   console.log(translatedName + " " + inputName + ": " + translatedName || inputName);
            //   setFilter({ ...filter, name: translatedName || inputName });
            // }}
            onChange={debounceSearch}
          />
          <datalist id="costumeNames">
            {costumeOptions.map((name) => (
              <option value={costumeNames[name] ? costumeNames[name] : name}></option> 
            ))}
          </datalist>
        </label>
        <label>
          角色:
          <select value={filter.chara} onChange={(e) => setFilter({ ...filter, chara: e.target.value })}>
            <option value="">所有角色</option>
            <option value="npc">NPC</option>
            <optgroup label="黑羊小隊">
              {charaOptions.map((chara) => (
                chara === 'seha' || chara === 'seulbi' || chara === 'yuri' || chara === 'j' || chara === 'tein' || chara === 'ria' ?
                <option value={chara} key={chara}>
                  {charaNames[chara]}
                </option>
                : null
              ))}
            </optgroup>
            <optgroup label="紅狼小隊">
              {charaOptions.map((chara) => (
                chara === 'nata' || chara === 'levia' || chara === 'harpy' || chara === 'tina' || chara === 'violet' ?
                <option value={chara} key={chara}>
                  {charaNames[chara]}
                </option>
                : null
              ))}
            </optgroup>
            <optgroup label="夜梟小隊">
              {charaOptions.map((chara) => (
                chara === 'wolfgang' || chara === 'luna' || chara === 'soma' || chara === 'bai' || chara === 'seth' ?
                <option value={chara} key={chara}>
                  {charaNames[chara]}
                </option>
                : null
              ))}
            </optgroup>
            <optgroup label="嚙鼠小隊">
              {charaOptions.map((chara) => (
                chara === 'mirae' || chara === 'chulsoo' || chara === 'eunha' || chara === 'lucy' || chara === 'aeri' ?
                <option value={chara} key={chara}>
                  {charaNames[chara]}
                </option>
                : null
              ))}
            </optgroup>
          </select>
        </label>
        <label>
          分類:
          <select value={filter.category} onChange={(e) => setFilter({ ...filter, category: e.target.value })}>
            <option value="">所有類別</option>
            {cateOptions.map((category) => (
              <option value={category} key={category}>
                {categoryNames[category]}
              </option>
            ))}
          </select>
        </label>
      </div>
      {setResultNum(filteredShuffledData.length)/* <font color="white">結果數：{setResultNum(filteredShuffledData.length)}</font> */}
      <div className="gallery">
        {filteredShuffledData.map((image, index) => (
          <div key={index} className="gallery-item">
            {/* <img 
              src={image.image.slice(0, image.image.length-4) + "h" + image.image.slice(image.image.length-4)} 
              alt={`${image.name} - ${image.chara}`} 
              onClick={() => openImage(image)}
              loading="lazy"
            /> */}
            <LazyLoadImage 
                src={image.image.slice(0, image.image.length-4) + "h" + image.image.slice(image.image.length-4)} 
                alt={`${image.name} - ${image.chara}`} 
                onClick={() => openImage(image, index)}
            />
            <div className="gallery-item-info">
              <ul>
                <li>時裝名稱： {costumeNames[image.name] || image.name}</li>
                <li>角色： {charaNames[image.chara] || image.chara}</li>
                <li>時裝類別： {categoryNames[image.category] || image.category}</li>
              </ul>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

Gallery.propTypes = {
  imageData: PropTypes.array.isRequired,
};

export default Gallery;
