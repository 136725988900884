import { ImageData } from "./GalleryData";
import Gallery from "./components/Gallery";
import Footer from "./components/Footer";
import { useState } from "react";
import ReactGA from "react-ga4";

function App() {
  const [resultNum, setResultNum] = useState(0);
  ReactGA.initialize("G-7C9EMS2MBS");
  return (
    <>
      <div className="App">
        {/* <Helmet>
          <title>Closers Illust Viewer</title>
          <meta name="description" content="Illustration viewer from Closers online, made with React." />
        </Helmet> */}
        <h1>Closers Illust Viewer</h1>
        <Gallery imageData={ImageData} setResultNum={setResultNum} />
      </div>
      <Footer resultNum={resultNum} />
    </>
  );
}

export default App;
