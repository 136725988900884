import React from 'react';

const Footer = (props) => {
	const scrollToTop = () => {
		window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
	};

	const { resultNum } = props;
	
	return (
		<footer className="footer">
			<div className='info'>
				<span className='showResultNum'>結果數：{resultNum}</span>
				© 2024 By 梧夜
				<button
					onClick={scrollToTop}
					className='btn_scrollToTop'
				>
					回到頂端
				</button>
			</div>
		</footer>
	);
}

export default Footer;
